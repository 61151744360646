import React, {useEffect, useState} from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/images/logo.png'
import LngSwitcher from '../Language/LngSwitcher'
import Navigation from '../Navigation'

import './Header.scss'

export default function Header() {
  const [btnMob, setBtnMob] = useState(false)
  const [scrolling, setScrolling] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > 0);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const className = `Header ${scrolling ? 'scrolling' : ''}`

  const menuBtnClick = (event) => {
    event.preventDefault()
    setBtnMob(!btnMob)
  }

  return (
    <div className={className}>
      <div className="header-wrap">
        <div className="logo-wrap">
          <Link to="/">
            <img src={logo} alt="logo" />
          </Link>
          {/*<h3>Anvarix</h3>*/}
        </div>
        <div className="mobile-header">
          <div className="menu-button" onClick={menuBtnClick}>
            <div className="line-btn" />
            <div className="line-btn" />
            <div className="line-btn" />
          </div>
        </div>
      </div>
      {btnMob && (
        <div className="menu-wrap">
          <nav>
            <Navigation mobVersion={true} />
          </nav>
          <LngSwitcher mobVersion={true} />
        </div>
      )}

      <nav>
        <Navigation />
      </nav>
      <div>
        <div>
          <a className="phone-link" href="tel:+380632072957">
            +393311919797
          </a>
        </div>
        <LngSwitcher />
      </div>
    </div>
  )
}
